import {TFuncKey, Namespace, KeyPrefix} from 'i18next';
import {DefaultNamespace} from 'react-i18next/TransWithoutContext';
import {TitleBase, TitleProps} from './TitleBase';
import {Trans, TransProps} from './Trans';
import {twMerge} from 'tailwind-merge';

export function Title<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(props: TransProps<K, N, TKPrefix, E> & TitleProps) {
  const {level, className, id} = props;

  return (
    <TitleBase
      level={level}
      className={twMerge('font-bold text-black uppercase', className)}
      id={id}
    >
      <Trans {...props} />
    </TitleBase>
  );
}
